.header[data-v-59fc4a2d] {
  width: 100%;
  height: 50px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 10px 10px 0;
}
[data-v-59fc4a2d] .avue-affix {
  position: relative !important;
}
[data-v-59fc4a2d] .van-tabs__line {
  background-color: #0087ff !important;
}
.dialog_content .agreeBtn[data-v-59fc4a2d] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  bottom: 0;
  padding: 15px;
  /* margin-top: 15px; */
  /* padding-bottom: 20px; */
}
.dialog_content .agreeBtn > div[data-v-59fc4a2d] {
  text-align: center;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 23px;
  line-height: 40px;
}
.dialog_content .agreeBtn .reject[data-v-59fc4a2d] {
  background: #f5faff;
  border: 1px solid #086de5;
  color: #086de5;
}
.dialog_content .agreeBtn .agree[data-v-59fc4a2d] {
  background: #086de5;
  color: #fff;
  border: 1px solid #086de5;
}
.dialog_content .van-cell[data-v-59fc4a2d]::after {
  border: none;
}
.dialog_content .opt_btns[data-v-59fc4a2d] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dialog_content .opt_btns > button[data-v-59fc4a2d] {
  margin: 0 15px;
}
.dialog_content .qz[data-v-59fc4a2d] {
  margin-top: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 85px;
  height: 40px;
  background: #086de5;
  border-radius: 20px;
  border: 1px solid #086de5;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
}
.dialog_content .qz1[data-v-59fc4a2d] {
  background: #f5faff;
  border: 1px solid #086de5;
  color: #086de5;
}
.dialog_content .qzimg[data-v-59fc4a2d] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  border: 1px solid #d4e4f5;
  border-radius: 10px;
  margin-bottom: 10px;
}
.dialog_content .forward[data-v-59fc4a2d] {
  margin-top: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #999999;
}
.dialog_content .forward span[data-v-59fc4a2d] {
  margin-left: 15px;
}
.dialog_content .forward .user[data-v-59fc4a2d] {
  color: #086de5;
}
.van-action-sheet__header[data-v-59fc4a2d] {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.van-action-sheet__header .van-icon-cross[data-v-59fc4a2d]:before {
  content: "\E6A7";
  color: #000000;
}
.van-nav-bar[data-v-59fc4a2d] {
  position: relative;
}
.statusIcon[data-v-59fc4a2d] {
  position: fixed;
  right: 10px;
  z-index: 10;
  top: 90px;
}
.statusIcon img[data-v-59fc4a2d] {
  width: 100px;
  height: 100px;
  display: block;
}
.exchangeCard[data-v-59fc4a2d] {
  overflow: auto;
  height: calc(100vh - 180px);
}