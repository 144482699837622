.recommendBox[data-v-898bd322] {
  margin: 0 10px;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 10px;
}
.recommendBox .txt[data-v-898bd322] {
  font-size: 14px;
  color: #999;
}
.recommendBox .btns[data-v-898bd322] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 10px;
}
.recommendBox .btns div[data-v-898bd322] {
  padding: 5px 10px;
  font-size: 14px;
  color: #000;
  background: #eaeeef;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 3px;
}
.attachment[data-v-898bd322] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px;
}
.attachment .uploader[data-v-898bd322] {
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: #212121;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.attachment .uploader img[data-v-898bd322] {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.attachment .uploader2[data-v-898bd322] {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #efefef;
}
.attachment .uploader2 .imgs[data-v-898bd322] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.attachment .uploader2 .del[data-v-898bd322] {
  color: #d6000f;
  font-size: 12px;
  margin-left: 10px;
}
.approval[data-v-898bd322] {
  margin: 0 10px;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 10px;
}
.approval .yuan[data-v-898bd322] {
  display: inline-block;
  background: #086de5;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}
.approval .yuan[data-v-898bd322]::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid #086de5;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: -2px;
}
.approval .name[data-v-898bd322] {
  margin-left: 10px;
  color: #086de5;
  font-weight: 600;
}