.foot-item[data-v-4faaa19e] {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 101;
  height: 66px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-right: 10px;
}
.foot-item .moreShow span[data-v-4faaa19e] {
  display: block;
  font-size: 12px;
  margin: 5px 0;
}
.dialog_content[data-v-4faaa19e] {
  padding: 10px 30px 20px;
}
.dialog_content .agreeBtn[data-v-4faaa19e] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  bottom: 0;
  padding: 15px;
  /* margin-top: 15px; */
  /* padding-bottom: 20px; */
}
.dialog_content .agreeBtn > div[data-v-4faaa19e] {
  text-align: center;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 23px;
  line-height: 40px;
}
.dialog_content .agreeBtn .reject[data-v-4faaa19e] {
  background: #f5faff;
  border: 1px solid #086de5;
  color: #086de5;
}
.dialog_content .agreeBtn .agree[data-v-4faaa19e] {
  background: #086de5;
  color: #fff;
  border: 1px solid #086de5;
}
.dialog_content .van-cell[data-v-4faaa19e]::after {
  border: none;
}
.dialog_content .opt_btns[data-v-4faaa19e] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dialog_content .opt_btns > button[data-v-4faaa19e] {
  margin: 0 15px;
}
.dialog_content .qz[data-v-4faaa19e] {
  margin-top: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 85px;
  height: 40px;
  background: #086de5;
  border-radius: 20px;
  border: 1px solid #086de5;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
}
.dialog_content .qz1[data-v-4faaa19e] {
  background: #f5faff;
  border: 1px solid #086de5;
  color: #086de5;
}
.dialog_content .qzimg[data-v-4faaa19e] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  border: 1px solid #d4e4f5;
  border-radius: 10px;
  margin-bottom: 10px;
}
.dialog_content .forward[data-v-4faaa19e] {
  margin-top: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #999999;
}
.dialog_content .forward span[data-v-4faaa19e] {
  margin-left: 15px;
}
.dialog_content .forward .user[data-v-4faaa19e] {
  color: #086de5;
}
.van-action-sheet__header[data-v-4faaa19e] {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.van-action-sheet__header .van-icon-cross[data-v-4faaa19e]:before {
  content: "\E6A7";
  color: #000000;
}
.btnR[data-v-4faaa19e] {
  width: 65px;
  height: 30px;
  border-radius: 21px !important;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  line-height: 10px;
  border: none;
}
.clear[data-v-4faaa19e],
.clear[data-v-4faaa19e]:hover {
  background-color: #fff !important;
  color: #666 !important;
  border: 1px solid #ccc !important;
}
.el-button[data-v-4faaa19e] {
  margin: 0 5px;
}
.filIcon[data-v-4faaa19e] {
  width: 5px;
  height: 5px;
  display: inline-block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAR5JREFUKFOVkaFOxEAQhv+ZbNKtJwEBDociqFNgmpwhOXsJggfAoLqbIPaSa1h3hgSDwIPoG4AgAYFFoCDB8ACIbdJ2SBvaHAemo7/vz8z8hIFDA3n8EbIsW2fmq6IoplEULYhoLCJfzDxJ0/T1l/AD3wO4BnBARM8hhFkcx/siMjfGjHphFWbmpzRNZ977HWPMi/f+wxiz1QrOubUoiu6I6EJEJkvwAsAmEeV1XR9ba5NW8N7fAHgQkfF/sIg4EUmste+d8FaW5VQpdWmM2fXet8nMfFvX9byDm/BeCCGMtNaPIlI2xzJzXlXVGYDDJrl7fyecA9gIIZwqpbaVUnsiclIUReKc+1zuqjtaaa0b6QiABpCHEOwq3K80pO1vw42MDRjbmG8AAAAASUVORK5CYII=);
  background-size: 100px;
  background-repeat: no-repeat;
}
.dialog_content-button .button-icon[data-v-4faaa19e] {
  text-align: center;
}
.dialog_content-button .button-icon img[data-v-4faaa19e] {
  width: 70px;
  height: 70px;
  display: block;
}
.dialog_content-button .button-icon .title[data-v-4faaa19e] {
  display: block;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  -webkit-background-clip: text;
}
.moreBtn[data-v-4faaa19e] {
  width: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #000000;
}
.moreBtn .dian[data-v-4faaa19e] {
  width: 21px;
  height: 5px;
  font-size: 32px;
}
.moreBtn .moreTxt[data-v-4faaa19e] {
  width: 60px;
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
}
.moreBtns[data-v-4faaa19e] {
  width: 100vw;
  background: #f2f2f2;
  padding: 20px 0;
}
.moreBtns .topBox[data-v-4faaa19e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 10px;
}
.moreBtns .button-icon[data-v-4faaa19e] {
  text-align: center;
  width: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.moreBtns .button-icon img[data-v-4faaa19e] {
  width: 60px;
  height: 60px;
  margin-bottom: 5px;
}
.moreBtns .button-icon .title[data-v-4faaa19e] {
  width: 60px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}
.close[data-v-4faaa19e] {
  height: 40px;
  background: #ffffff;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
}