.mainBox[data-v-59fc4a2d] {
  width: 100vw;
  height: calc(100vh - 110px);
  font-size: 14px;
  overflow: scroll;
}
.mainBox .value[data-v-59fc4a2d] {
  height: 40vh;
  overflow: scroll;
}
.mainBox .value2[data-v-59fc4a2d] {
  height: 74vh;
  overflow: scroll;
}